import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CustomTable } from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { dateToFilenameFormat } from "shared/utils";
import { orderAPI } from "api/services/Order";
import { FutureSaleData } from "models/FutureSales";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import DropDownRegularButton from "components/DropDownRegularButton/DropDownRegularButton";
import DownloadDropdownMenuItem from "components/DownloadDropdownMenuItem/DownloadDropdownMenuItem";

function FutureSalesPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const { data: tableData, isFetching: tableDataIsFetching } =
    orderAPI.useGetFutureSalesQuery({
      limit: rowPerPage,
      offset: skip,
    });
  const {
    data: exportTableDataXlsx,
    isFetching: exportTableDataXlsxIsFetching,
    isError: exportTableDataXlsxIsError,
  } = orderAPI.useGetFutureSalesExportDataQuery({ report_type: "xlsx" });

  const {
    data: exportTableDataPdf,
    isFetching: exportTableDataPdfIsFetching,
    isError: exportTableDataPdfIsError,
  } = orderAPI.useGetFutureSalesExportDataQuery({ report_type: "pdf" });

  const futureSalesHeaders: Header[] = [
    { id: "factory", label: constants.FACTORY },
    { id: "model", label: constants.MODEL },
    { id: "trim", label: constants.TRIM },
    { id: "orderDeadline", label: constants.ORDER_DEADLINE },
    {
      id: "eta",
      label: constants.ETA,
      subHeaders: [
        { label: constants.STOCK, id: "stock", minWidth: "90px" },
        { label: constants.OTHER_ADJ, id: "other", minWidth: "90px" },
      ],
    },
    { id: "month_later", label: constants.MONTH_LATER },
    { id: "remark", label: constants.REMARK },
  ];

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((futureSales: FutureSaleData) => ({
        values: [
          futureSales.factory,
          futureSales.model,
          futureSales.model_type,
          futureSales.order_deadline,
          futureSales.stock_eta,
          futureSales.other_eta,
          futureSales.month_later,
          futureSales.comment,
        ],
      }));
    }
    return [];
  }, [tableData]);

  const date = dateToFilenameFormat(new Date());

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.FUTURE_SALES} />
        <Box>
          <LastUpdateBlock fileType="model_future_sales" />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DropDownRegularButton
          label={constants.EXPORT}
          popoverAnchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          popoverTransformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <DownloadDropdownMenuItem
            text={constants.TO_EXCEL}
            filename={`Future_sales_data_${date}.xlsx`}
            data={exportTableDataXlsx}
            isFetching={exportTableDataXlsxIsFetching}
            isError={exportTableDataXlsxIsError}
          />
          <DownloadDropdownMenuItem
            text={constants.TO_PDF}
            filename={`Future_sales_data_${date}.pdf`}
            data={exportTableDataPdf}
            isFetching={exportTableDataPdfIsFetching}
            isError={exportTableDataPdfIsError}
          />
        </DropDownRegularButton>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={futureSalesHeaders}
        rows={tableRows}
        count={tableData?.count}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getItemsForPage={(items: number) => setSkip(items)}
        stickyColumnIndex={1}
      />
    </Box>
  );
}

export default FutureSalesPage;
