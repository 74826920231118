// export const EMAIL = "Email";
// export const PASSWORD = "Password";
// export const YOUR_PASSWORD = "Your password";
// export const LOGIN_TO_YOUR_ACCOUNT = "Login to your account";
// export const DASHBOARD = "Dashboard";
// export const BACKORDERS = "Backorders";
// export const BACKPRDER_OVERVIEW_PER_MODEL = "Backorder overview per model";
// export const DEALER_PERFORMANCE = "Dealer performance";
// export const ORDER_TO_DELIVERY = "Order to delivery";
// export const SIGN_OUT = "Sign out";
// export const SALES_DOCUMENT = "Sales document";
// export const EXTERIOR_COLOUR = "Exterior colour";
// export const INTERIOR_COLOUR = "Interior colour ";
// export const LOCATION = "Location ";
// export const DOCUMENT_DATE = "Document Date";
// export const VEHICLE_USAGE = "Vehicle usage";
// export const DESCRIPTION_OF_MERKTYPE = "Description of Merktype";
// export const STATUS = "Status";
// export const ESTIMATED_TIME_OF_ARRIVAL = "Estimated Time of Arrival";
// export const ETA = "ETA";
// export const SEARCH = "Search";
// export const DEALERS = "Dealers";
// export const NONE = "None";
// export const FUTURE_SALES = "Future sales";
// export const USER_INFO = "User info";
// export const CHANGE_PASSWORD = "Change password";
// export const NEW_PASSWORD = "New password";
// export const PASSWORD_CHANGED = "Your password has been successfully changed";
// export const CONFIRM = "Confirm";
// export const CHANGE = "Change";
// export const CONFIRM_NEW_PASSWORD = "Confirm new password";
// export const INCORRECT_PASSWORD =
//   "Your current password is incorrect. Please try again";
// export const RESERVATION = "Reservation";
// export const LICENSE_RETURN = "License return";
// export const STOCK = "Stock";
// export const REGISTRATION = "Registration";
// export const SOLD_CARS = "Sold cars";
// export const CARS_TO_SELL = "Cars to sell";
// export const SOLD_ABOVE_TARGET = "Sold above target";
// export const FOR_DATE = "For date";
// export const CURRENT = "Current";
// export const TARGET = "Target";
// export const PERFORMANCE = "Performance";
// export const PROGRESSION = "Progression";
// export const THIS_MONTH = "This month";
// export const THIS_QUARTER = "This quarter";
// export const THIS_YEAR = "This year";
// export const MATCH_ORDERS = "Match orders";
// export const COUNTRY = "Country";
// export const DEALER = "Dealer";
// export const FACTORY = "Factory";
// export const MODEL = "Model";
// export const SOLD = "Sold";
// export const FLEET = "Fleet";
// export const DEMO = "Demo";
// export const SHOW = "Show";
// export const TOTAL = "Total";
// export const SEARCH_VIN_NUMBER = "Search VIN number";
export const SEARCH_RESERVATION_NUMBER = "Zoek reserveringsnummer";
// export const NETHERLANDS = "Netherlands";
// export const ALL_MODELS = "All models";
// export const ALL_DEALERS = "All dealers";
// export const ALL_FACTORIES = "All factories";
// export const ALLOCATION = "Allocation";
// export const ROUND_1 = "Round 1";
// export const ROUND_2 = "Round 2";
// export const ROUND_3 = "Round 3";
// export const ROUND_4 = "Round 4";
// export const TOTAL_BACKORDERS = "Total backorders";
// export const SHIPPED = "Shipped";
// export const AWAITEING_SHIPMENT = "Awaiting shipment";
// export const IN_PRODUCTION = "In production";
// export const NOT_PRODUCTION = "Not production";
// export const OTD_MONITORING = "OTD Monitoring";
// export const ANNUAL_TARGET = "Annual Target";
// export const DEALER_TARGET = "Dealer Target";
// export const MTD = "MTD";
// export const PRECENTAGE = "%";
// export const ACHIEVEMENT = "Achievement";
// export const TRANSIT = "Transit";
// export const VS_TARGET = "vs Target";
// export const TRIM = "Trim";
// export const ORDER_DEADLINE = "Order Deadline";
// export const PRODUCTION = "Production";
// export const ROTTERDAM = "Rotterdam";
// export const REMARK = "Remark";
// export const REGISTRATION_YTD = "Registration YTD";
// export const OR_AGAIN = "OR aging";
// export const FROM_0_T0_30 = "0-30";
// export const FROM_0_T0_90 = "0-90";
// export const FROM_31_T0_45 = "31-45";
// export const FROM_46_T0_90 = "46-90";
// export const FROM_91_T0_180 = "91-180";
// export const FROM_181_T0_365 = "181-365";
// export const FROM_181_T0_270 = "181-270";
// export const FROM_271_T0_365 = "271-365";
// export const FROM_366 = "366-";
// export const STOCK_AGING = "Stock Aging";
// export const OLD_STOCK = "Old Stock";
// export const OLD_RATE = "Old Rate";
// export const BAD_STOCK = "Bad Stock";
// export const PAGE_TOTAL = "Page total";
// export const GRAND_TOTAL = "Grand total";
// export const GRAND_PAGE_TOTAL_DIFFERENCE = "Grand - Page total difference";
// export const FROM = "From";
// export const TO = "To";
// export const OTHER = "Other";
// export const MODELS = "Models";
// export const CLEAR = "Clear";
// export const STOCK_MANAGEMANT = "Stock Management";
// export const AGING_STOCK = "Aging stock";
// export const O_R = "O/R";
// export const OPEN_REGISTRATION_TARGET = "Open Registrations Target";
// export const TOTAL_OPEN_REGISTRATION = "Total Open Registrations";
// export const TOTAL_RESERVATION = "Total Reservations";
// export const RESERVATION_TARGET = "	Reservations Target";
// export const TOTAL_REGISTER = "Total Registrations";
// export const REGISTRATION_TARGET = "Registrations Target";
// export const REGISTERED_CARS = "Registered cars";
// export const CARS_TO_REGISTER = "Cars to Register";
// export const REGISTERED_ABOVE_TARGET = "Registered above target";
// export const RESERVATIONS = "Reservations";
// export const REGISTRATIONS = "Registrations";
// export const OPEN_REGISTRATIONS = "Open Registrations";
// export const SALES_PERFORMANCE = "Sales performance";
// export const MONTH_GOAL = "Month goal";
// export const MONTH_REGISTRATION = "Month registration";
// export const REGISTER_ABOVE_TARGET = "Register above target";
// export const EXPORT_DATA = "Export data";
// export const EXPORT_TABLE = "Export table";
// export const EXPORT = "Export";
// export const PLEASE_CHOOSE_OPTION = "Please choose option";
// export const STOCK_AGING_GRAND_TOTAL = "Stock aging grand total";
// export const OR_MORE_30_DAYS = "OR’s > 30 Days";

export const DEFAULT_ROWS_PER_PAGE = 20;
export const ROWS_PER_PAGE_ALL = -1;

export const EMAIL = "E-mail";
export const PASSWORD = "Wachtwoord";
export const YOUR_PASSWORD = "Wachtwoord";
export const LOGIN_TO_YOUR_ACCOUNT = "Inloggen met accountgegevens";
export const FORGOT_PASSWORD = "Wachtwoord vergeten?";
export const DASHBOARD = "Dashboard";
export const BACKORDERS = "Backorders";
export const BACKORDER_OVERVIEW_PER_MODEL = "Backorder overzicht per model";
export const DEALER_PERFORMANCE = "Dealer performance";
export const ORDER_TO_DELIVERY = "Order to delivery";
export const SIGN_OUT = "Afmelden";
export const SALES_DOCUMENT = "Reserveringsnummer";
export const EXTERIOR_COLOUR = "Exterieur Kleur";
export const INTERIOR_COLOUR = "Interieur Kleur";
export const LOCATION = "Locatie";
export const DOCUMENT_DATE = "Order datum";
export const ORDER_DATE = "Order Datum";
export const VEHICLE_USAGE = "Ordersoort";
export const DESCRIPTION_OF_MARKTYPE = "Omschrijving model";
export const STATUS = "Status";
export const ESTIMATED_TIME_OF_ARRIVAL = "Verwachte aankomstdatum";
export const ETA = "ETA";
export const SEARCH = "Zoeken";
export const DEALERS = "Dealers";
export const NONE = "Geen";
export const FUTURE_SALES = "Future sales";
export const USER_INFO = "Accountinformatie";
export const CHANGE_PASSWORD = "Wijzig wachtwoord";
export const NEW_PASSWORD = "Nieuw wachtwoord";
export const PASSWORD_CHANGED = "Het wachtwoord is aangepast";
export const CONFIRM = "Bevestigen";
export const CHANGE = "Wijzigen";
export const CONFIRM_NEW_PASSWORD = "Nieuw wachtwoord bevestigen";
export const INCORRECT_PASSWORD =
  "Dit wachtwoord is onjuist. Probeer het nogmaals";
export const RESERVATION = "Reserveringen";
export const ALLOCATIE = "Allocatie";
export const LICENSE_RETURN = "Kentekenaanvraag";
export const STOCK = "Voorraad";
export const REGISTRATION = "Registratie";
export const SOLD_CARS = "Geretailde auto’s";
export const CARS_TO_SELL = "Te retailen auto’s";
export const RETAILS_OVER_120 = "Retails voor 120%";
export const FOR_DATE = "Voor datum";
export const CURRENT = "Huidig";
export const TARGET = "Doelstelling";
export const TARGET_EN = "Target";
export const PERFORMANCE = "Performance";
export const PROGRESSION = "Voortgang";
export const THIS_MONTH = "Huidige maand";
export const THIS_QUARTER = "Huidig kwartaal";
export const THIS_YEAR = "Huidig jaar";
export const MATCH_ORDERS = "Match orders";
export const COUNTRY = "Land";
export const DEALER = "Dealer";
export const FACTORY = "Fabriek";
export const MODEL = "Model";
export const MODEL_TYPE = "Model type";
export const SOLD = "Sold";
export const FLEET = "Fleet";
export const DEMO = "Demo";
export const SHOW = "Show";
export const TOTAL = "Totaal";
export const SEARCH_VIN_NUMBER = "Zoek VIN";
export const NETHERLANDS = "Nederland";
export const ALL_MODELS = "Alle modellen";
export const ALL_DEALERS = "Alle dealers";
export const ALL_FACTORIES = "Alle fabrieken";
export const ALLOCATION = "Allocatie Rapport";
export const ROUND = "Ronde";
export const ROUND_1 = "Ronde 1";
export const ROUND_2 = "Ronde 2";
export const ROUND_3 = "Ronde 3";
export const ROUND_4 = "Ronde 4";
export const TOTAL_BACKORDERS = "Totaal backorders";
export const SHIPPED = "Onderweg";
export const AWAITING_SHIPMENT = "Afwachting shipment";
export const IN_PRODUCTION = "In productie";
export const NOT_PRODUCTION = "Niet in productie";
export const OTD_MONITORING = "OTD monitor";
export const ANNUAL_TARGET = "Jaarlijkse doelstelling";
export const DEALER_TARGET = "Dealer doelstelling";
export const MTD = "Huidige maand";
export const PERCENTAGE = "%";
export const ACHIEVEMENT = "Prestatie";
export const TRANSIT = "Onderweg";
export const VS_TARGET = "vs Doelstelling %";
export const TRIM = "Uitvoering";
export const ORDER_DEADLINE = "Bestel deadline";
export const PRODUCTION = "Productie";
export const ROTTERDAM = "Rotterdam";
export const REMARK = "Opmerkingen";
export const MONTH_LATER = "1~2 maanden langer";
export const REGISTRATION_YTD = "Registratie huidig jaar";
export const OR_AGAIN = "OR status";
export const FROM_0_T0_30 = "0-30";
export const FROM_0_T0_90 = "0-90";
export const FROM_31_T0_45 = "31-45";
export const FROM_46_T0_90 = "46-90";
export const FROM_91_T0_180 = "91-180";
export const FROM_181_T0_365 = "181-365";
export const FROM_181_T0_270 = "181-270";
export const FROM_271_T0_365 = "271-365";
export const FROM_181 = "181+";
export const FROM_366 = "366+";
export const STOCK_AGING = "Ouderdom voorraad";
export const OLD_STOCK = "Old Stock";
export const OLD_RATE = "Old Stock %";
export const BAD_STOCK = "Bad Stock";
export const PAGE_TOTAL = "Totaal pagina";
export const GRAND_TOTAL = "Totaal";
export const GRAND_PAGE_TOTAL_DIFFERENCE = "Overige pagina’s";
export const FROM = "Van";
export const TO = "Tot";
export const OTHER = "Overige"; // "other" as noun
export const OTHER_ADJ = "Overig"; // "other" as adjective
export const TO_DO = "Te realiseren";
export const MODELS = "Modellen";
export const CLEAR = "Verwijderen";
export const STOCK_MANAGEMENT = "Voorraad Management";
export const AGING_STOCK = "Aging stock";
export const O_R = "O/R";
export const OPEN_REGISTRATION_TARGET = "OR Target";
export const TOTAL_OPEN_REGISTRATION = "Totale OR status";
export const TOTAL_RESERVATION = "Totale reserveringen";
export const RESERVATION_TARGET = "Reservering Target";
export const TOTAL_REGISTER = "Totale registraties";
export const REGISTRATION_TARGET = "Dealer doelstelling";
export const REGISTERED_CARS = "Geregistreerde auto’s";
export const CARS_TO_REGISTER = "Te registreren auto’s";
export const REGISTERED_ABOVE_TARGET = "Overperformance";
export const RESERVATIONS = "Reserveringen";
export const REGISTRATIONS = "Registraties";
export const OPEN_REGISTRATIONS = "Open Registraties";
export const SALES_PERFORMANCE = "Verkoop prestatie";
export const MONTH_GOAL = "Maanddoelstelling";
export const MONTH_REGISTRATION = "Registraties";
export const REGISTER_ABOVE_TARGET = "Overperformance";
export const EXPORT_DATA = "Export volledige gegevens";
export const EXPORT_TABLE = "Export tabel";
export const DOWNLOAD_XLSX = "Excel downloaden";
export const EXPORT = "Export";
export const TO_EXCEL = "to Excel";
export const TO_PDF = "to PDF";
export const PLEASE_CHOOSE_OPTION = "Kies een optie";
export const TOTAL_TARGET = " Dealer doelstelling";
export const STOCK_AGING_GRAND_TOTAL = "Old stock";
export const OR_MORE_30_DAYS = "OR's > 30 Dagen";
export const ENTER_YOUR_EMAIL =
  "Vul het e-mailadres in en er zal een nieuwe uitnodiging verstuurd worden om het wachtwoord te wijzigen.";
export const PASSWORD_RESET_INSTRUCTIONS_ARE_SENT =
  "De instructies om het wachtwoord te wijzigen zullen verzonden worden naar de e-mail";
export const EMAIL_IS_INCORRECT = "Het e-mailadres is niet correct";
export const EMAIL_IS_INVALID = "Het e-mailadres is niet geldig";
export const BACK_TO_LOG_IN = "Terug naar de login pagina";
export const PASSWORD_MUST_BE_LETTERS_AND_NUMBERS =
  "Het wachtwoord moet letters en nummers bevatten.";
export const CONFIRM_PASSWORD = "Bevestig het nieuwe wachtwoord";
export const PASSWORDS_MUST_MATCH = "De wachtwoorden dienen gelijk te zijn";
export const PASSWORD_IS_SAVED =
  "Het wachtwoord is opgeslagen en kan nu gebruikt worden om in te loggen.";
export const RESET_LINK_IS_EXPIRED =
  "De link om het wachtwoord te wijzigen is verlopen.";
export const LOG_IN_PAGE = "Login pagina";
export const RESET_PASSWORD = "Wachtwoord herstellen";
export const ENTER_NEW_PASSWORD = "Voer nieuw wachtwoord in";
export const ENTER_EMAIL = "Vul het e-mailadres in";
export const passwordIsTooShort = (passwordMinLength: any) =>
  `Wachtwoord is te kort (minimale lengte ${passwordMinLength} karakters)`;
export const TOTAL_MONTHLY_GOAL = "TOTAAL Dealer doelstelling - Huidige maand";
export const TOTAL_ANNUAL_GOAL = "TOTAAL Jaarlijkse doelstelling";
export const TOTAL_GOAL = "TOTAAL doelstelling";
export const LAST_UPDATE = "Laatste update";
export const ALL = "Alles";
export const FILTER = "FILTREREN";
export const DELETE_ACCOUNT = "Verwijderen account";
export const SURE_ABOUT_ACCOUNT_DELETION =
  "Het account zal worden verwijderd. Wilt u doorgaan?";
export const YES = "Ja";
export const CANCEL = "Annuleren";
export const RESERVATION_NUMBER = "Reserveringsnummer";
export const RESERVATION_NUMBERS = "Reserveringsnummers";
export const VIN = "VIN";
export const MODEL_DESCRIPTION = "Model Beschrijving";
export const ADMIN_ROLE = "hmnl_admin";
export const DEALER_SALES_ROLE = "dealer_sales";
export const DEALER_ADMIN_ROLE = "dealer_admin";
export const ACCOUNT_MANAGER_ROLE = "hmnl_manager";
export const EXPECTED_DELIVERY_DAYS = "Verwachte levertijd in werkdagen";
export const delivery_due = "Verwachting overschreden";
export const days_1_2 = "1-2 dagen";
export const days_3_4 = "3-4 dagen";
export const days_5_6 = "5-6 dagen";
export const days_6_plus = "6+ dagen";
export const RFT = "RFT lijst";
export const RFT_DETAILS = "RFT";
export const DEMO_SHOWROOM = "Demo Showroom";
export const DEMO_SHOWROOM_OVERVIEW = "Demo Showroom Overzicht";
export const DEMO_SHOWROOM_REQUESTS = "Demo Showroom Aanmelden";
export const OVERVIEW = "Overview";
export const PERFORMANCE_OVERVIEW = "Performance Overzicht";
export const DOELSTELLING = "Doelstelling";
export const ZONES = "Zones";
export const ZONE = "Zone";
export const CLAIMS = "Claims";
export const CLAIMS_PAGE = "Uitbetaling";
export const VS_LAST_YEAR_TARGET = "vs Vorig jaar %";
export const CURRENT_MONTH_REGISTRATIONS = "Maand registratie";
export const CURRENT_YEAR_REGISTRATIONS = "Jaar registratie";
export const DEMO_AND_SHOWROOM_OVERVIEW = "Demo and Showroom Overview";
export const POTENTIAL = "Potentieel";
export const RETAIL_POTENTIAL = "Retail Potentieel";
export const DATE_OPERATIONAL_FORMAT = "YYYY-MM-DD";
export const DATE_FILENAME_FORMAT = "DD_MM_YYYY";
export const SALES_ORDER = "Sales Order";
export const DEALER_CODE = "Dealer Code";
export const CATEGORY = "Categorie";
export const TYPE_CLAIM = "Type Claim";
export const INCENTIVE_PLAN_NUMBER = "Incentive plan nr";
export const TEXT = "Omschrijving";
export const DOCUMENT_NUMBER = "Factuur";
export const NET_AMOUNT = "Netto Waarde";
export const OR_MORE_THAN_30_BRACKETS = "O/R (>30 days)";
export const ROUND_1_NL = "Ronde 1";
export const ROUND_2_NL = "Ronde 2";
export const ROUND_3_NL = "Ronde 3";
export const ROUND_4_NL = "Ronde 4";
export const CLAIM_CODES_OVERVIEW = "Claim Codes";
export const CLAIM_CODE = "Claim Code";
export const SALES_OPPORTUNITY = "Verkoopkans Name";
export const CLIENT_CORPORATE_NAME = "Bedrijfsnaam";
export const CLAIM_CODES_OVERVIEW_PAGE = "Claim codes overview";
export const DISCOUNT = "Klant Korting";
export const ORDER_MATCHING_IS_BEING_PROCESSED =
  "Overeenkomende orders wordt verwerkt";
export const ORDER_MATCHING_HAS_BEEN_COMPLETED =
  "Overeenkomende orders zijn voltooid";
export const ORDER_MATCHING_HAS_BEEN_FAILED =
  "Overeenkomende orders zijn gefaald";
export const FREE_STOCK_HMNL = "Vrije Voorraad HMNL";
export const FREE_STOCK = "Vrije Voorraad";
export const LAST_MATCH = "Laatste match";
export const UNKNOWN_FACTORY = "Unknown factory";
export const UNKNOWN_MODEL = "Unknown model";
export const UNKNOWN_FACTORY_OR_MODEL_REGEX = /Unknown (factory|model)/;
export const PENDING = "In behandeling";
export const APPROVED = "Goedgekeurd";
export const REJECTED = "Afgekeurd";
export const ERROR = "Fout";
export const ALLOCATION_DATE = "Allocatie Datum";
export const STOCK_AGE = "Voorraad Leeftijd";
export const REAL_RETAILS = "Real retails";
export const OVERSCORE = "Overscore";
export const SOORT = "Soort";
